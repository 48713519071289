<script setup>
const backend = useBackend();
const {data: brands} = useAsyncData(async () => await backend.getBrands());
useHead({
  title: "Leasing samochodu - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
});
</script>

<template>
  <Container>

    <!-- header -->
    <div class="flex flex-col lg:flex-row gap-8">
      <div class="space-y-6 w-full">
        <div class="text-2xl text-center lg:text-left lg:text-5xl text-vehis-red">
          <h1>
            <span class="mb-2 lg:mb-6 leading-snug">LEASING SAMOCHODU DLA PRZEDSIĘBIORCÓW I&nbsp;KONSUMENTÓW</span>
          </h1>
        </div>
        <div class="text-xl text-center lg:text-left leading-normal font-light space-y-6">
          <p>Masz wypatrzony samochód - sfinansuj go w VEHIS.</p>
          <p>Leasing VEHIS to szybka procedura i prosta umowa.</p>
          <p>Sprawdź wysokość raty, złóż wniosek on-line i podpisz umowę w 15 minut.</p>
          <div class="mb-10 flex lg:block justify-center items-center flex-col">
            <p class="text-sm mb-2">Jesteśmy członkiem ZPL</p>
            <VectorZplLogo class="w-52" />
          </div>
        </div>
        <div class="font-light">
          <div class="text-center lg:text-left">
            <div class="block lg:inline-block mr-1">Dowiedz się więcej o naszej ofercie:</div>
            <div class="block lg:inline-block mb-8">
              <NuxtLink class="inline-block text-azure font-medium"
                        :href="{name: 'static-offer-entrepreneur'}">Leasing dla przedsiębiorcy
              </NuxtLink>
              <span> | </span>
              <NuxtLink class="inline-block text-azure font-medium"
                        :href="{name: 'static-offer-consumer'}">Leasing dla konsumenta
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full">
        <FormFinancing />
      </div>
    </div>

    <StaticSeparator />

    <BlockHowTo />

    <StaticSeparator class="lg:!w-1/3" />

    <BlockBestFeaturesCompared />

    <StaticSeparator />

    <StaticHeader>Często zadawane pytania</StaticHeader>

    <div class="lg:mx-20 mt-6 mb-10">
      <StaticFaqItem question="Czy w ofercie leasingu VEHIS mogę sfinansować samochód używany?" opened>
        Tak, finansujemy zarówno samochody nowe, jak i używane do 3 lat (z przebiegiem do 150 tys. km). Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża.
      </StaticFaqItem>
      <StaticFaqItem question="Jakie dokumenty będą potrzebne do złożenia wniosku o finansowanie?">
        Jeżeli prowadzisz działalność gospodarczą wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam numer NIP swojej firmy, dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Jeżeli nie prowadzisz działalności oprócz wniosku i zgód poprosimy Cię dodatkowo o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.
      </StaticFaqItem>
      <StaticFaqItem question="Jak długo muszę prowadzić działalność, żeby dostać finansowanie w VEHIS?">
        W VEHIS finansujemy startupy już od pierwszego dnia działalności gospodarczej.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę skorzystać z oferty VEHIS, jeżeli nie prowadzę działalności gospodarczej?">
        Nie musisz być przedsiębiorcą, żeby skorzystać z naszej oferty. Finansujemy zarówno przedsiębiorców jak i konsumentów.
      </StaticFaqItem>

      <StaticFaqItem question="Czy samochód używany musi być serwisowany w autoryzowanej stacji?">
        Samochód powinien być serwisowany zgodnie z warunkami gwarancji producenta, w autoryzowanych stacjach obsługi. W przypadku samochodów, dla których gwarancja wygasła, możliwe jest serwisowanie poza autoryzowanymi stacjami. Jednocześnie rekomendujemy korzystanie z naszej sieci serwisów partnerskich, których wykaz dostępny jest
        <NuxtLink :to="backend.getDownloadLinkForFile('services')" class="text-azure underline" target="_blank">tutaj</NuxtLink>
        .
      </StaticFaqItem>
    </div>


  </Container>
  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
